@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');

.swiper-pagination .swiper-pagination-bullet{
    color: red;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}   

.open-sans{
  font-family: "Open Sans", sans-serif;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  /* font-style: italic; */
}

.bg-color{
  background-color: #64993d;
}




